import {Injectable, OnDestroy} from '@angular/core';
import {interval, Subject, Subscription} from 'rxjs';
import * as moment from 'moment';
import {DataService} from './data.service';

@Injectable()
export class OrderService implements OnDestroy {

	orders$: Subject<{ orders: any[], reset: boolean }> = new Subject() as Subject<{ orders: any[], reset: boolean }>;
	public lastUpdatedOn: Date = new Date();
	sub: Subscription;
	private initial = true;
	today: Date = new Date();
	query: any = {};
	orderList: any[] = [];
	serviceLoading = false;

	constructor(private http: DataService) {
		this.today.setHours(this.today.getHours() - 124);
	}

	resetTime() {
		if (this.query.hasOwnProperty('__created_on__datetime_gte')) {
			this.lastUpdatedOn = moment(this.query['__created_on__datetime_gte']).utc().toDate();
		} else {
			this.lastUpdatedOn.setHours(this.lastUpdatedOn.getHours() - 24);
		}
	}

	async init(query?: any, reset?: boolean) {
		if (query) {
			this.query = query;
		} else {
			this.query = {};
		}
		this.resetTime();
		this.destroy();
		await this.getOrders(reset);
		this.sub = interval(15000)
			.subscribe(async () => {
				await this.getOrders();
			});
	}

	async getOrders(reset?: boolean) {
		const currentTime: Date = new Date();
		currentTime.setMinutes(currentTime.getMinutes() - 1);
		try {
			const query = {
				last_updated_on: this.lastUpdatedOn.toJSON(),
				__created_on__datetime_gte: (new Date()).toJSON(),
			};

			for (const queryKey in this.query) {
				if (this.query.hasOwnProperty(queryKey)) {
					query[queryKey] = this.query[queryKey];
				}
			}
			const res = await this.http.query(query, 'order_polling', 'order');
			this.lastUpdatedOn = currentTime;
			this.initial = false;
			this.orders$.next({orders: res.data, reset: reset});
			return;
		} catch (e) {
			console.error(e);
		}
	}

	setOrderList(list) {
		this.orderList = list;
	}

	destroy() {
		if (this.sub) {
			this.sub.unsubscribe();
		}
	}

	ngOnDestroy(): void {
		this.destroy();
	}

}
